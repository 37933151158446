
@font-face {
  font-family: 'NeueHaasPro6-Roman';
  src: url('/assets/fonts/NeueHaasPro6/NeueHaasDisplay-Roman.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'NeueHaasPro6-Medium';
  src: url('/assets/fonts/NeueHaasPro6/NeueHaasDisplay-Medium.ttf') format('truetype');
  font-weight: 600;
}

@mixin customFont($fontFamily, $fontSize, $lineHeight, $letterSpacing) {
  font-family: $fontFamily;
  font-size: $fontSize;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}

$fontBase: 'NeueHaasPro6-Roman', Arial, sans-serif;

@mixin font-display-l{@include customFont('NeueHaasPro6-Roman', 60px, 74px, 2%)}
@mixin font-display-m{@include customFont('NeueHaasPro6-Roman', 44px, 56px, 2%)}
@mixin font-display-s{@include customFont('NeueHaasPro6-Roman', 36px, 48px, 1%)}

@mixin font-heading-l{@include customFont('NeueHaasPro6-Roman', 32px, 40px, 1%)}
@mixin font-heading-m{@include customFont('NeueHaasPro6-Roman', 20px, 28px, 1%)}
@mixin font-heading-s{@include customFont('NeueHaasPro6-Medium', 16px, 24px, 1%)}

@mixin font-heading-l{@include customFont('NeueHaasPro6-Roman', 32px, 40px, 1%)}
@mixin font-heading-m{@include customFont('NeueHaasPro6-Roman', 20px, 28px, 1%)}
@mixin font-heading-s{@include customFont('NeueHaasPro6-Roman', 16px, 24px, 1%)}

@mixin font-body-l{@include customFont('NeueHaasPro6-Roman', 16px, 24px, unset)}
@mixin font-body-m{@include customFont('NeueHaasPro6-Roman', 14px, 20px, unset)}
@mixin font-body-s{@include customFont('NeueHaasPro6-Roman', 12px, 16px, unset)}

@mixin font-body-bold-l{@include customFont('NeueHaasPro6-Medium', 16px, 24px, unset)}
@mixin font-body-bold-m{@include customFont('NeueHaasPro6-Medium', 14px, 20px, unset)}
@mixin font-body-bold-s{@include customFont('NeueHaasPro6-Medium', 12px, 16px, unset)}
