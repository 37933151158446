.icon-clickable {
  color: var(--secondaryColor);
  cursor: pointer;
}

.icon-large {
  font-size: 40px;
}

.icon-small {
  font-size: 20px;
}

.icon-mini {
  font-size: 14px;
}

.icon-micro {
  font-size: 10px;
}

.close-icon {
  cursor: pointer;
}

.close-icon-position {
  right: 50px;
  position: absolute;
}
.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
.icon-pulse {
  -webkit-animation: icon-spin 1s infinite steps(8);
  animation: icon-spin 1s infinite steps(8);
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
