@import "variables";

@font-face {
  font-family: 'ePowerDirect';
  src:  url('icomoon/icomoon.eot?h6dg5s');
  src:  url('icomoon/icomoon.eot?h6dg5s#iefix') format('embedded-opentype'),
    url('icomoon/icomoon.ttf?h6dg5s') format('truetype'),
    url('icomoon/icomoon.woff?h6dg5s') format('woff'),
    url('icomoon/icomoon.svg?h6dg5s#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ePowerDirect' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-ccs {
  &:before {
    content: $icon-ccs;
  }
}
.icon-cee {
  &:before {
    content: $icon-cee;
  }
}
.icon-chademo {
  &:before {
    content: $icon-chademo;
  }
}

.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-creditcard {
  &:before {
    content: $icon-creditcard;
  }
}
.icon-dot {
  &:before {
    content: $icon-dot;
  }
}
.icon-envelope {
  &:before {
    content: $icon-envelope;
  }
}

.icon-header-arrow-left {
  &:before {
    content: $icon-header-arrow-left;
  }
}

.icon-powerlock {
  &:before {
    content: $icon-powerlock;
  }
}
.icon-magnifier {
  &:before {
    content: $icon-magnifier;
  }
}
.icon-paypal-lined {
  &:before {
    content: $icon-paypal-lined;
  }
}
.icon-selected-dot {
  &:before {
    content: $icon-selected-dot;
  }
}
.icon-speechbubble-questionmark {
  &:before {
    content: $icon-speechbubble-questionmark;
  }
}
.icon-stop {
  &:before {
    content: $icon-stop;
  }
}
.icon-type-1 {
  &:before {
    content: $icon-type-1;
  }
}
.icon-type-2 {
  &:before {
    content: $icon-type-2;
  }
}
.icon-amazon-pay {
  &:before {
    content: $icon-amazon-pay;
  }
}
.icon-apple-pay {
  &:before {
    content: $icon-apple-pay;
  }
}
.icon-google-pay {
  &:before {
    content: $icon-google-pay;
  }
}
.icon-charging {
  &:before {
    content: $icon-charging;
  }
}
.icon-header-help {
  &:before {
    content: $icon-header-help;
  }
}
.icon-header {
  &:before {
    content: $icon-header;
  }
}

