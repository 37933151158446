/* You can add global styles to this file, and also import other style files */
@import '../src/assets/css/normalize';
@import '@angular/material/prebuilt-themes/purple-green.css';
@import 'swiper/swiper-bundle.css';
@import '../src/assets/css/common';
@import '../src/assets/css/fonts';
@import '../src/assets/fonts/icons';
@import '../src/assets/fonts/ePowerDirect.scss';
@import '../src/assets/css/vaylens.scss';

// TODO: Load branding global
// Innogy Branding (Quick fix for branding, because the branding is only loaded in start-page. policy and cookie page direct links, dont load that component)
:root {
  --swiper-theme-color: #007aff;
  --swiper-navigation-size: 44px;
  --backgroundColor: #ffffff;
  --errorColor: #b9280a;
  --warningColor: #f59b00;
  --successColor: #64b42d;
  --infoColor: #00aae1;
  --disabledColor: #c4c3c1;
  --labelColor: #888888;
  --iconUrl: 'https://www.rwe-mobility.com/cmmTest/gw/cmm/static/B2B_PORTAL/DEFAULT/APP_ICON_image.png';
  --buttonBorderColor: #eeeeee;
  --buttonSuccessBackgroundColor: #64b42d;
  --buttonSuccessTextColor: #ffffff;
  --buttonInfoBackgroundColor: #00aae1;
  --buttonInfoTextColor: #ffffff;
  --buttonRedBackgroundColor: none;
  --buttonRedBorderColor: #b9280a;
  --buttonRedTextColor: #b9280a;
  --buttonDisabledBackgroundColor: #c4c3c1;
  --buttonDisabledTextColor: #ffffff;
  --buttonFooterBorderColor: #c4c3c1;
  --footerBackgroundColor: #f6f6f6;
  --footerBorderColor: #eeeeee;
  --footerTextColor: #000000;
  --evseStatusAvailable: #64b42d;
  --evseStatusOccupiedCharging: #00aae1;
  --evseStatusOccupiedStarting: #f59b00;
  --evseStatusUnknownError: #b9280a;
  --evseStatusUnknownNew: #c4c3c1;
  --loadingIndicatorBackground: rgba(255, 255, 255, 0.8);
  --loadingIndicatorColor: #c81e82;
  --mdc-theme-surface: #f6f6f6;
  --mdc-theme-text-primary-on-background: #000000;
  --mdc-dialog-container-color: white;
}

.swiper-pagination-bullet-active {
  background: none;
  color: $black;
}

.icon-dot,
.icon-selected-dot {
  color: $blackC;
  padding: 0 2px;
}

.swiper-pagination-bullet {
  background: none;
  color: $blackC;
}

.charging-overlay-swiper-bullet {
  color: $white;
}

html,
body {
  font-size: 100%;
  height: 100%;
  margin: auto;
  background-color: #eeeeee;

  .mat-toolbar-row,
  .mat-toolbar-single-row {
    height: 3rem;
    margin: auto;
    left: unset;
  }

  @media only screen and (min-width: 768px) {
    max-font-size: 25px !important;
    .form-container__search-icon {
      z-index: 10;
    }
  }
}

body *,
body *:after,
body *:before {
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: $fontBase;
}

.sidenav-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  min-width: 100%;
}

.header {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  .back {
    margin-right: 12px;
  }

  .header-help {
    margin-left: auto;
  }
}

.sidenav-drawer {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  background-color: $white !important;
  color: black;
  .sidenav-content {
    padding-top: 3rem;
  }
}

.mat-form-field-underline {
  display: none;
}

input.mat-input-element {
  float: right;
  border: 1px solid black;
  border-radius: 4px;
}

input[type='radio'] {
  width: 24px;
  height: 24px;
}

.input-label {
  margin-bottom: 2px;
  margin-left: 2px;
  font-size: smaller;
  color: var(--labelColor);
}

.input-error {
  border-color: red;
}

.form-container {
  position: relative;

  &__search-icon {
    position: absolute;
    left: 1.8rem;
    top: 16px;
    vertical-align: middle;
    z-index: 10;
  }

  &__input-element {
    position: relative;

    &--full-width-rounded {
      width: calc(100% - 1.5rem);
      max-width: calc(100% - 1.5rem);
      margin: 0 auto 15px;
      height: 3rem;
      border-radius: 8px;
      border: 1px solid $color-border-white;
      outline: none;
      padding-left: 3rem;

      &.input-error {
        border-color: $error;
        color: $error;
      }
    }
  }

  &__form-error {
    padding: 10px 0 10px 5%;
    text-align: left;
    color: $error;
  }
}

.example-full-width {
  width: 100%;
}
/** Import Variables and Mixins */
.success-snackbar {
  background: var(--successColor);
  margin-bottom: 30px !important;
}

.error-snackbar {
  background: var(--errorColor);
  margin-bottom: 30px !important;
}

.warning-snackbar {
  background: var(--warningColor);
  margin-bottom: 30px !important;
}

.info-snackbar {
  background: var(--infoColor);
  margin-bottom: 30px !important;
}

.color-evsestatus-AVAILABLE {
  color: var(--evseStatusAvailable);
}

.color-evsestatus-i-am-charging {
  color: var(--evseStatusOccupiedCharging);
}

.color-evsestatus-OCCUPIED,
.color-evsestatus-OCCUPIED_CHARGING,
.color-evsestatus-OCCUPIED_STARTING,
.color-evsestatus-OCCUPIED_SUSPENDED,
.color-evsestatus-OCCUPIED_FINISHING,
.color-evsestatus-OCCUPIED_NONE,
.color-evsestatus-OCCUPIED_RESERVED {
  color: var(--evseStatusOccupiedStarting);
}

.color-evsestatus-UNKNOWN_ERROR,
.color-evsestatus-OUT_OF_SERVICE_ERROR,
.color-evsestatus-UNKNOWN_INVALID_RESPONSE,
.color-evsestatus-UNKNOWN_INVALID_UNAVAILABLE,
.color-evsestatus-UNKNOWN_NO_HEARTBEAT {
  color: var(--evseStatusUnknownError);
}

.color-evsestatus-UNKNOWN,
.color-evsestatus-OUT_OF_SERVICE_INOPERATIVE,
.color-evsestatus-UNKNOWN_NEW,
.color-evsestatus-UNKNOWN_UNAVAILABLE,
.color-evsestatus-UNKNOWN_NOT_RELEVANT {
  color: var(--evseStatusUnknownNew);
}

.color-plug-type {
  color: $blackB;
}

.loading-indicator {
  background: var(--headerBackgroundColor);
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  z-index: 1000;

  mat-spinner {
    position: absolute;
    top: calc(50vh - 50px);
    left: calc(50vw - 50px);

    circle {
      stroke: var(--headerTextColor);
      stroke-width: 3% !important;
    }
  }
}

.phoneLink {
  color: $black;
  text-decoration: none;
}

.help-page {
  font-size: 0.9rem;
  text-align: center;
  height: calc(100vh - 3rem);
  overflow: auto;
  padding: 0 1rem 0 1rem;

  app-menu-controls {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__content {
    margin-top: 20px;
    text-align: left;
    &--close-button {
      margin-top: -2rem;
      font-size: 2rem;
      float: right;
      cursor: pointer;
    }
    p:last-child {
      margin-bottom: 100px;
    }
  }
  &__info-block {
    text-align: left;
    width: 100%;
    max-width: 768px;
    background-color: var(--footerBackgroundColor);
    padding: 1rem 1rem 1rem 3rem;
    line-height: 1.2;
    font-size: 0.8rem;
  }

  &__help-icon {
    padding-top: 1rem;
    font-size: 15vw;
  }

  &__header {
    font-size: 1.2rem;
    text-align: center;

    &--sub-header {
      margin: 1rem 0 0 0;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;

    .copyright {
      margin: 4rem 0 0 0;
      padding: 0 0 1rem 0;
    }
  }
}

.cdk-overlay-pane {
  margin: 0 auto !important;
}

.mat-dialog-container {
  background-color: white;
  color: unset;
  text-align: center;
  h1 {
    font-size: 2rem;
    color: $error;
    margin: 1rem auto;
  }
}

.error-dialog {
  position: relative;

  &__close-button {
    position: absolute;
    font-size: 2rem;
    right: -10px;
    top: -10px;
  }
  .chargeport-error {
    font-weight: bold;
    color: $error;
    text-decoration: underline;
  }
}

.page-contents {
  background-color: $white;
  padding: 0 1rem;
  margin-top: 3rem;

  iframe {
    max-width: 300px;
    margin-top: 5px;
  }
  h1 {
    text-align: center;
    font-size: 1.2rem;
    padding: 2rem 0 1.2rem 0;
  }

  .selectPaymentButtons {
    margin-bottom: 2rem;
  }

  .requireInvoice {
    margin: 0 auto 2rem;
    padding: 1rem 0;
    border-top: 1px solid $grey;
  }

  .accept-terms {
    p {
      color: $blackC;
      margin-top: 1rem;
      font-size: 0.8rem;
      line-height: 1.2;
    }
  }

  .payment-capture {
    color: $black;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    text-align: left;
    width: 100%;
    max-width: 768px;
    background-color: $blackF6;
    line-height: 1.2;
    font-size: 0.8rem;
  }

  .footer {
    width: 100vw;
    max-width: 420px;
    background-color: $blackF6;
    border-top: 1px solid $blackE;
    padding: 1rem;
    margin-left: -1rem;
  }

  .price {
    float: right;
    font-weight: bold;
  }

  &__chargeport-location {
    margin-bottom: 2rem;
    text-align: center;
  }

  &__chargeport-details-header {
    font-size: x-large;
    text-align: center;
  }

  &__tariff-name {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  &__map {
    width: 100vw;
    max-width: 420px;
    min-height: 120px;
    height: 25vh;
    position: relative;
    left: -1rem;
    background-size: cover;
    background-image: url(/assets/img/ui/maps.png);
    margin-bottom: 4rem;
  }

  &__logo {
    position: absolute;
    left: calc(50%);
    bottom: -3rem;
    transform: translateX(-50%);
    background-color: var(--headerBackgroundColor);
    border-radius: 50%;
    border: 3px solid $white;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.3);

    img {
      width: 6em;
      height: 6em;
      border-radius: 50%;
      object-fit: contain;
    }
  }

  &--pricing-table {
    text-align: center;
    padding: 1rem 0;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    overflow: auto;
  }

  &--reservation-amount {
    text-align: right;
    font-weight: bold;
    margin: 10px 0;
  }

  &--pricings {
    margin-top: 40px;
  }

  &--pricing-line1 {
    @include font-body-bold-l;
  }

  &--pricing-vat {
    font-weight: normal;
    color: $black8;
    font-size: small;
  }

  &--chargeport-id {
    text-align: center;
    padding: 1.3rem 0 1rem 0;
    font-size: 1.5rem;
  }

  &--chargeport-availability {
    text-align: center;
    font-size: 1.5rem;
    line-height: 1;
  }

  &__payment-methods {
    padding: 30px 0;
    border-bottom: 1px solid var(--footerBorderColor);

    h1 {
      margin: 0 0 10px;
      padding: 0;
    }

    &--header {
      text-align: center;
      font-size: larger;
    }

    &--icons {
      text-align: center;
    }

    &--icon {
      display: inline-block;
      text-align: center;
      padding: 5px;
      line-height: 1;
      img {
        margin: 0 auto 5px;
        width: 2rem;
      }
    }
  }

  &--plug-type {
    text-align: center;
    padding-bottom: 1.3rem;
    font-size: 1rem;
  }

  &--footer {
    width: 100vw;
    max-width: 420px;
    background-color: var(--footerBackgroundColor);
    border-top: 1px solid var(--footerBorderColor);
    padding: 1.5rem 1rem;
  }

  &__evse-list {
    display: table;
    border-collapse: collapse;
    width: 100%;
  }

  &__plug-list {
    display: table-row;
    border-bottom: 1px solid var(--footerBorderColor);
    width: 100%;

    &:first-child {
      border-top: 1px solid var(--footerBorderColor);
    }
  }

  &__tariffs {
    float: left;
  }

  &__plug-list--item {
    display: table-cell;
  }

  &__plug-list--item-padded {
    padding: 1rem 2rem;
    line-height: 1.5;
  }

  &__info-block {
    text-align: right;
    border-bottom: 1px solid var(--footerBorderColor);
    padding: 0.8rem 0;
    position: relative;
    min-height: 3.5rem;

    &--first-block {
      margin-top: 1rem;
      border-top: 1px solid var(--footerBorderColor);
    }

    &.provider-block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    label {
      position: absolute;
      left: 0;
      font-size: 1rem;
      color: $blackA;

      &.for-provider-name {
        position: relative;
        padding-right: 20px;
      }
    }

    .provider-name {
      margin-left: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.mat-menu-panel {
  background: var(--backgroundColor);
  color: var(--textColor);
  max-width: calc(100vw - 3rem) !important;
  width: calc(100vw - 3rem) !important;
  text-align: center;
  margin-right: 1rem;
}

button.mat-menu-item {
  text-align: center;

  span {
    margin: auto;
  }
}

.main-content {
  margin: auto;
  max-width: 420px;
  padding-bottom: 200px;
}

.background-map {
  background-size: cover;
  background-image: url('/assets/img/ui/vaylens.png');
  background-color: $color-background;
}

/* PayOne Zeugs */
input,
.inputIframe,
select {
  display: block;
}

#errorOutput {
  text-align: center;
  color: var(--errorColor);
  display: block;
}

.warning {
  text-align: center;
  color: var(--warningColor);
  display: block;
}

.termsLink {
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}

.mat-simple-snackbar,
.cookie-snack-bar {
  background-color: var(--buttonTextColor) !important;
  margin: 0 !important;
  border-radius: 0 !important;
  display: block !important;
  simple-snack-bar {
    div {
      margin: 10px 0;
      button {
        max-height: 45px;
        line-height: 3rem;
        border-radius: 3rem;
        font-size: 1rem;
        width: 120px;
        position: relative;
        color: var(--buttonTextColor);
        border: 1px solid var(--buttonBorderColor);
        background-color: var(--buttonBackgroundColor);
      }
    }
  }
}

.mat-option {
  color: black;
  border-bottom: 1px solid whitesmoke;
}

.mat-autocomplete-panel {
  background: white;
}

.mdc-dialog .mat-mdc-dialog-surface {
  &.mdc-dialog__surface {
    padding: 20px;
  }
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 4px;
}

.echarge-banner-panel {
  .mat-bottom-sheet-container {
    background: var(--footerBackgroundColor);
    margin: 16px;
    padding: 8px 16px;
    min-width: 250px;
    border-radius: 15px;
  }

  .echarge-banner-backdrop {
    background: transparent;
  }
}

input {
  font-family: $fontBase;
}

.creditcard-left:before {
  content: url('/assets/img/icons/branding/mastercard-logo.svg');
  position: absolute;
  left: 2.5rem;
  top: 0.75rem;
}

.paypal-left:before {
  content: url('/assets/img/icons/branding/paypal-logo.svg');
  position: absolute;
  left: 2.5rem;
  top: 0.75rem;
}
