/*--- VARIABLES ---*/

/*** Bottom charge status banner ***/
$charge-status-banner-height: 50px;

/*** Base Paddings ***/

$pagePadding: 40px;
$gridPadding: 10px;
$titleMargin: 20px;
$topNotificationHeight: 69px;

/*** Base Colors ***/

$initColor: #FFC800;
$yellowB: #F59B00;
$yellowM: #D29B00;
$redB: #B9280A;
$redM: #B92B0A;
$fuchsiaB: #E60055;
$fuchsiaM: #A50032;
$greenB: #64B42D;
$greenM: #00875A;
$blueB: #019BA5;
$blueM: #015F69;
$indigoB: #00AAE1;
$indigoM: #143C8C;
$purpleB: #C81E82;
$purpleM: #780A5F;

$backgroundColor: #F5F5F5;
$primary: $yellowB;
$primaryHover: #FFB100;
$secondary: $blueB;
$secondaryHover: #07AFBA;
$error: $redB;
$success: $greenB;
$blueHover: $backgroundColor;
$grey: #C4C3C1;

$white: #ffffff;
$whiteB: rgba(255, 255, 255, 0.8);
$black: #000000;
$blackA: rgba(60, 60, 60, 1);
$blackB: rgba(60, 60, 60, 0.8);
$blackC: rgba(60, 60, 60, 0.6);
$blackD: rgba(60, 60, 60, 0.4);
$blackE: rgba(60, 60, 60, 0.1);
$blackF: rgba(60, 60, 60, 0.05);
$blackE2: #e2e2e2;
$blackF6: #f6f6f6;
$black8: #888888;

$footerColor: $blackA;
$hubjectIconColor: #7ab4c1;

/* Colors */

$fontPrimary: #fff;
$fontSecondary: $secondary;
$fontAdditional: $black;
$fontDisabled: rgba(0, 0, 0, 0.3);

:root {
  // generic colors
  --backgroundColor: #ffffff;
  --textColor: #000000;
  --errorColor: #b9280a;
  --warningColor: #f59b00;
  --successColor: #64B42D;
  --infoColor: #00aae1;
  --disabledColor: #c4c3c1;
  --labelColor: #888888;

  // standard icon url
  --iconUrl: 'https://www.rwe-mobility.com/cmmTest/gw/cmm/static/B2B_PORTAL/DEFAULT/APP_ICON_image.png';

  // button colors
  --buttonBorderColor: #eeeeee;
  --buttonSuccessBackgroundColor: #64B42D;
  --buttonSuccessTextColor: #ffffff;
  --buttonInfoBackgroundColor: #00aae1;
  --buttonInfoTextColor: #ffffff;
  --buttonRedBackgroundColor: none;
  --buttonRedBorderColor: #b9280a;
  --buttonRedTextColor: #b9280a;
  --buttonDisabledBackgroundColor: #C4C3C1;
  --buttonDisabledTextColor: #ffffff;
  --buttonFooterBorderColor: #c4c3c1;

  // footer colors
  --footerBackgroundColor: #f6f6f6;
  --footerBorderColor: #eeeeee;
  --footerTextColor: #000000;

  // evse status colors
  --evseStatusAvailable: #64B42D;
  --evseStatusOccupiedCharging: #00aae1;
  --evseStatusOccupiedStarting: #f59b00;
  --evseStatusUnknownError: #b9280a;
  --evseStatusUnknownNew: #C4C3C1;

  // loading indicator colors
  --loadingIndicatorBackground: rgba(255, 255, 255, 0.8);
  --loadingIndicatorColor: #c81e82;
}


input::-ms-clear {
    display: none;
}

