@use '@angular/material' as mat;
@include mat.core();

@import 'fonts';

$candy-app-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$azure-palette
  ),
  typography: (
    plain-family: $fontBase
  )
));

html {
  @include mat.all-component-themes($candy-app-theme);
  @include mat.all-component-typographies($candy-app-theme);
}
