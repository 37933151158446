$icon-arrow-down: "\e900";
$icon-arrow-left: "\e901";
$icon-arrow-right: "\e902";
$icon-ccs: "\e903";
$icon-cee: "\e904";
$icon-chademo: "\e905";
$icon-charging: "\e906";
$icon-close: "\e907";
$icon-creditcard: "\e908";
$icon-dot: "\e909";
$icon-envelope: "\e90a";
$icon-header: "\e90b";
$icon-header-arrow-left: "\e90c";
$icon-header-help:  "\e90d";
$icon-magnifier: "\e90e";
$icon-paypal-lined: "\e90f";
$icon-powerlock: "\e910";
$icon-selected-dot: "\e911";
$icon-speechbubble-questionmark: "\e912";
$icon-stop: "\e913";
$icon-type-1: "\e914";
$icon-type-2: "\e915";
$icon-amazon-pay: "\e916";
$icon-apple-pay: "\e917";
$icon-google-pay: "\e918";

