@import "fonts";

$color-foreground: #ffffff;
$color-background: #f1f1fa;
$color-icons: #7E7E95;
$color-border-white: #DADAF1;

$color-accent-base:#475EFF;
$color-accent-base-disabled:#475EFF4D;
$color-accent-text:#1D2D9A;
$color-accent-selected:#E0E4FF;
$color-accent-icons: #6679FF;

$color-info-text:#1C464F;
$color-info-selected:#C4EFF8;
$color-info-background:#D6F4FA;


:root{
    --headerBackgroundColor: #ffffff;
    --headerTextColor: #232325;
    --buttonBackgroundColor: #475EFF;
    --buttonTextColor: #ffffff;
}

@mixin button-base{
    @include font-body-bold-l;
    position: relative;
    height: 48px;
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    eop-icon{
        font-size: 24px;
    }

    &.mtop{
        margin-top: 16px;
     }
}

.button-filled-primary{
    @include button-base;   
    background-color: $color-accent-base;
    color: $color-foreground;  

    eop-icon{
        color: $color-border-white 
    }

    &.disabled{
        background-color: $color-accent-base-disabled;
    }
}

.button-filled-secondary{
    @include button-base;      
    background-color: $color-accent-selected;
    color: $color-accent-text;

    eop-icon{
        color: $color-accent-icons;         
    }
   
}

.button-filled-tertiary{
    @include button-base;      
    background-color: $color-info-text;
    color: $color-info-selected;

    eop-icon{
        color:  $color-info-background;
    }   
}